<template>
    <div id="app">
        <div id="top_btn_div">
            <img src="../../assets/image/search.png" @click="showSearchDialog=true;"/>
        </div>
        <div id="serch_div">
            <van-dialog v-model="showSearchDialog" title="搜索" show-cancel-button @confirm="onConfirm">
                <van-cell-group>
                    <van-field v-model="serarchModel.name" label="姓名" placeholder="请输入车主姓名" />
                    <van-field v-model="serarchModel.scrapOrderProviderName" label="提交人" placeholder="请输入提交人姓名" />
                    <van-field v-model="serarchModel.licensePlate" label="车牌号" placeholder="请输入车牌号" />
                    <van-field  readonly clickable clearable name="picker" :value="serarchModel.orderStatusText"
                                label="状态" placeholder="点击选择状态" @click="action_show = true" />
                </van-cell-group>
            </van-dialog>
            <van-action-sheet
                v-model="action_show"
                :actions="actions"
                cancel-text="取消"
                close-on-click-action
                @cancel="onCancel"
                @select="onSelect"
                />
            <van-action-sheet
                v-model="cardShow"
                :actions="cardActions"
                cancel-text="取消"
                description="请选择打款用户银行卡"
                @select="actionSelect"
                close-on-click-action
            />
            <van-action-sheet v-model="date_show" title="选择日期">
                <div class="content">
                    <van-datetime-picker
                    type="date"
                    title="选择年月日"
                    />
                </div>
            </van-action-sheet>         
        </div>
        <div class="cards_div" style="margin-top:2.6rem;">
            <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
                <van-card v-for="(item,index) in tableData" :key="index"
                    :title="item.licensePlate"
                    :thumb="$cosImg(item.frontLicensePhoto)"
                    :thumb-link="'/morderdetail?id='+item.orderId" style="background-color: #fff;">
                    <template #tags>
                        <van-tag plain :color="tagStyle(item.orderStatus)" type="danger" style="font-size: 10px;height: 20px;font-weight: 500;border-radius: 11px;padding-left:10px;">{{$enum.myOrderStatus[item.orderStatus]}}</van-tag>
                    </template>
                    <template #desc>
                        <div>
                            {{item.model}}
                        </div>
                        <div class="address">
                            {{item.provinceName+"  "+item.cityName + "  "+item.countryName}}
                        </div>
                        <div>
                            <label class="colour" >系统估价：</label><span class="words">￥<span class="number">{{Math.floor(item.customerPrice)}}</span></span>
                        </div>
                        <div v-if="item.scrapOrderProviderName">
                            <label>提交人：{{item.scrapOrderProviderName}}</label>
                        </div>
                    </template>
                    <template v-if="item.orderStatus!==80&&item.orderStatus!='-1'" #footer>
                        <div @click="form.id=item.id" style="margin-top:10px;">
                            <van-button size="small" @click="showLicensePhotos"  v-if="!item.frontLicensePhoto||!item.backLicensePhoto" >补充行驶证</van-button>
                            <van-button size="small" @click="invalidOrder(item.id)" v-if="item.orderStatus<40">作废</van-button>
                            <van-button size="small" :type="item.receiverBankId?'default':'primary'"  @click="showScrapUserCrads(item)"  :style="{backgroundColor:item.receiverBankId?'#4600af':'#ee0a24',border: 'none',color:'white'}">{{item.receiverBankId?'更换银行卡':'绑定银行卡'}}</van-button>
                        </div>
                    </template>
                </van-card>
                <van-empty v-if="tableData.length!=0" description="下拉刷新当前页面"></van-empty>
                <van-empty image="search" v-if="tableData.length==0" description="没有更多了" />
                <van-dialog v-model="photos" title="补充行驶证" :closeOnClickOverlay='true' :showConfirmButton="false">
                    <div style="text-align:center;">
                        <van-uploader :after-read="uploadFrontCerts"  :max-count="1">
                                <van-button block  >上传主页</van-button>
                        </van-uploader>  
                        <van-uploader :after-read="uploadBackCerts"  :max-count="1">
                                <van-button block >上传副页</van-button>
                        </van-uploader>  
                    </div>
                    <div style="padding:10px;">
                        <van-button style="width:48%;" @click="photos=false" >取消</van-button>
                        <van-button style="width:48%;" @click="uploadCerts" type="primary">确定</van-button>
                    </div>
                </van-dialog>
                <van-dialog v-model="linkShow" title="分享绑卡链接（10分钟有效）">
                    <input type="text" style="width: 75%;" id="success_form_input" readonly="readonly" v-model="link"/>
                    <button ref="copy" data-clipboard-action="copy" data-clipboard-target="#success_form_input" @click="copyLink">复制</button>
                </van-dialog>
            </van-pull-refresh>
        </div>
        
    </div>
</template>
<script>
import ClipboardJS from "clipboard";
import {indexActive} from '../../store/provider'
import {getOrderList} from '../../api/provider'
import { Button,Tag,PullRefresh,Card,ActionSheet,Empty,NavBar,CellGroup,Cell,Field,Uploader,Dialog } from 'vant';
import {SaveLicensePhoto,SetReceiveBankCard,CreateAuthCode,getBankCardByScrapUser,invalidOrder} from '../../api/provider'
    export default({
        name:'myorder',
        components:{
            [Button.name]: Button,
            [Tag.name]: Tag,
            [PullRefresh.name]: PullRefresh,
            [Card.name]: Card,
            [ActionSheet.name]: ActionSheet,
            [Empty.name]: Empty,
            [NavBar.name]: NavBar,
            [CellGroup.name]: CellGroup,
            [Cell.name]: Cell,
            [Field.name]: Field,
            [Uploader.name]: Uploader,
            [Dialog.Component.name]: Dialog.Component,
        },
        props:{
            SubmitStatus:{
                type:Number,
                default:1
            }
        },
        data() {
            return {
                copyBtn:'',
                link:'',
                linkShow:false,
                cardShow:false,
                cardOrderId:'',
                orderMobile:'',
                scrapUserId:'',
                photos:false,
                isInit:false,
                actions: [],
                cardActions:[],
                isLoading:false,
                date_show:false,
                action_show:false,
                showSearchDialog:false,
                columns:[],
                totalPages:'',
                serarchModel:{
                    name:'',
                    licensePlate:'',
                    start_date:'',
                    end_date:'',
                    orderStatus:'',
                    orderStatusText:'',
                    scrapOrderProviderName:'',
                },
                pageindex:1,
                tableData:[],
                form:{
                    id:'',
                    frontLicensePhoto:'',
                    backLicensePhoto:''
                }
            }
        },
        methods:{
            copyLink() {
                this.copyBtn = new ClipboardJS(this.$refs.copy);
                let _this = this;
                let clipboard = _this.copyBtn;
                clipboard.on('success', function() {
                    _this.$toast({ /*这是使用了element-UI的信息弹框*/
                        message: '复制成功！',
                        type: 'success'
                    });
                });
                clipboard.on('error', function() {
                    _this.$toast({
                        message: '复制失败，请手动选择复制！',
                        type: 'error'
                    });
                });
                
            },
            actionSelect(action){
                console.log(action);
                if(action.id){
                    this.$dialog.confirm({
                        title: '确定选择此银行卡？',
                        message: action.name+"\n"+action.subname,
                    })
                    .then(() => {
                         SetReceiveBankCard({orderId:this.cardOrderId,bankCardId:action.id})
                        .then(res=>{
                            this.$toast({type:res.status?'success':'fail',message:res.message});
                            this.initTableData(this);
                        })
                    })
                    .catch(() => {
                    });
                }else{
                    CreateAuthCode({mobile:this.orderMobile}).then(res=>{
                        if(res.status){
                            var href = window.location.href;
                            var params = {code:res.data,mobile:this.orderMobile,userId:this.scrapUserId};
                            this.link = href.substring(0,href.lastIndexOf('/'))+"/bank/checkBankUser?code="+params.code+"&mobile="+params.mobile+"&userId="+params.userId;
                            this.linkShow = true;
                            this.$nextTick(() => {
                                this.copyLink();
                            });
                            //this.$router.push({path:'/bank/checkBankUser',query:{code:res.data,mobile:this.orderMobile,userId:this.scrapUserId}});
                        }else{
                            this.$toast.fail(res.message);
                        }
                    });
                }
            },
            showScrapUserCrads(item){
                this.cardActions = [{ name: '加载中', loading: true }]
                this.cardShow=true;
                this.cardOrderId = item.orderId;
                this.orderMobile = item.mobile;
                this.scrapUserId = item.scrapUserId;
                getBankCardByScrapUser({scrapUserId:item.scrapUserId}).then(res=>{
                    this.cardActions = [];
                    if(res&&res.length>0){
                    //if(false){
                        res.forEach(e => {
                            this.cardActions.push({ name:(item.receiverBankId==e.id?'已选择：':'')+e.cardNo,subname:e.bankName+' '+e.name,id:e.id,})
                        });                        
                    }
                    this.cardActions.push({ name: "绑定新的银行卡",color:'red'})
                });
            },
            showLicensePhotos(){
                this.photos =true;
            },
            invalidOrder(cid){
            this.$dialog.confirm({
                title: '提示',
                message: '确认作废吗？',
                })
                .then(() => {
                    invalidOrder({id:cid}).then(res=>{
                        this.$toast({
                            type:res.status?'success':'fail',
                            message:res.message
                        });
                        var index = this.tableData.map(item => item.id).indexOf(cid)
                        this.tableData.splice(index,1);
                    });
                }).catch(() => {});                
            },
            tagStyle(status){
                switch(status){
                    case 90:
                        return '#07c160';
                    case 80:
                        return '#c1c1c1';
                    default:
                        return '#1989fa';
                }
            },
            initTableData(that){
                that = that||this;
                if(that.isInit||(that.totalPages&&that.pageindex>that.totalPages)){
                    return;
                }else{
                    that.isInit = true;
                }
                var body = {
                    IsDesc:true,
                    PageSize:10,
                    PageIndex:that.pageindex,
                    SearchContent:that.serarchModel.name,
                    LikeLicense:that.serarchModel.licensePlate,
                    InStatus:that.serarchModel.orderStatus,
                    SortField:'LastModificationTime',
                    ScrapOrderProviderName:that.serarchModel.scrapOrderProviderName,
                    SubmitStatus:that.SubmitStatus?1:0
                }
                this.$toast.loading({duration: 0,forbidClick: true,message: '加载中'});
                getOrderList(body).then(res=>{
                    this.$toast.clear();
                    var data = res&&(res.content||[]);
                    data.forEach(e => {
                        that.tableData.push(e);
                    });
                    if(data.length>0){
                        that.pageindex++;
                    }
                    that.totalPages = res.totalPages;
                    that.isInit = false;
                    that.isLoading = false;
                });
            },
            getDocumentTop() {
                var scrollTop = 0, bodyScrollTop = 0, documentScrollTop = 0;
                if (document.body) {
                    bodyScrollTop = document.body.scrollTop;
                }
                if (document.documentElement) {
                    documentScrollTop = document.documentElement.scrollTop;
                }
                scrollTop = (bodyScrollTop - documentScrollTop > 0) ? bodyScrollTop : documentScrollTop;    return scrollTop;
            },
            getWindowHeight() {
                var windowHeight = 0;
                if (document.compatMode == "CSS1Compat") {
                    windowHeight = document.documentElement.clientHeight;
                } else {
                    windowHeight = document.body.clientHeight;
                }
                return windowHeight;
            },
            getScrollHeight() {
                var scrollHeight = 0, bodyScrollHeight = 0, documentScrollHeight = 0;
                if (document.body) {
                    bodyScrollHeight = document.body.scrollHeight;
                }
                if (document.documentElement) {
                    documentScrollHeight = document.documentElement.scrollHeight;
                }
                scrollHeight = (bodyScrollHeight - documentScrollHeight > 0) ? bodyScrollHeight : documentScrollHeight;    return scrollHeight;
            },
            onCancel(){
                this.serarchModel.orderStatus = '';
                this.serarchModel.orderStatusText = '';
            },
            uploadFrontCerts(file){
                var filename = file.file.name;
                    var ext = filename.substr(filename.lastIndexOf('.'));
                    if(!this.$util.validUplodImgExt(ext)){
                        this.$toast("不支持的图片格式");
                        return;
                    }
                    this.$toast.loading({duration: 0,forbidClick: true,message: '上传中'});
                    this.$util.uploadFile(ext,file,(err,data)=>{
                        this.$toast.clear();
                        var filename = data.Location.substr(data.Location.lastIndexOf('/')+1);
                        this.form.frontLicensePhoto = this.$cosImg(filename);
                        this.$toast.success('上传主页成功');
                    });
            },
            uploadBackCerts(file){
                var filename = file.file.name;
                    var ext = filename.substr(filename.lastIndexOf('.'));
                    if(!this.$util.validUplodImgExt(ext)){
                        this.$toast("不支持的图片格式");
                        return;
                    }
                    this.$toast.loading({duration: 0,forbidClick: true,message: '上传中'});
                    this.$util.uploadFile(ext,file,(err,data)=>{
                        this.$toast.clear();
                        var filename = data.Location.substr(data.Location.lastIndexOf('/')+1);
                        this.form.backLicensePhoto = this.$cosImg(filename);
                        this.$toast.success('上传副页成功');
                    });
            },
            uploadCerts(){
                SaveLicensePhoto(this.form).then(res=>{
                    if(res.status){
                        this.form = {};
                        this.$toast.success('上传成功');
                        this.onRefresh();
                        this.photos = false;
                    }else{
                        this.$toast.fail(res.message);
                    }
                })
                return;             
            },
            onConfirm(){
                this.pageindex = 1;
                this.tableData = [];
                this.initTableData(this);
            },
            onSelect(item){
                this.serarchModel.orderStatusText = item.name;
                this.serarchModel.orderStatus = item.id;
            },
            onRefresh(){
                this.isLoading = true;
                this.pageindex = 1;
                this.tableData = [];
                this.initTableData(this);
            },
            scrollEvent(){
                var active = indexActive.get();
                if(active == 0 &&   (this.getScrollHeight()-10) <= this.getWindowHeight() + this.getDocumentTop()){
                    this.initTableData(this);
                }
            }
        },
        destroyed(){
            window.removeEventListener('scroll',this.scrollEvent);
        },
        mounted(){
            this.initTableData(this);
            for(var i in this.$enum.userOrderStatus){
                this.actions.push({name:this.$enum.userOrderStatus[i],id:i});
            }
        },
        created(){
            window.addEventListener('scroll',this.scrollEvent);
        }
    })
</script>
<style scoped>
@import './myorder.css';
</style>