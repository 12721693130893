<template>
    <div id="app_div_userinfo">
        <!-- 用户信息 -->
        <div class="user_infos">
            <div class="user_logo"><img src="../../assets/image/banshou.png"/></div>
            <div class="userinfo_top" >
                <label class="user_name" >{{user.name}}</label>
                <div class="cell_left">
                    <van-tag plain :color="tagStyle(user.providerStatus)" 
                    style="background:rgba(112,59,193,0.10);height:1.3rem;padding: 0rem 0.5rem;font-weight: 500;border: 1px solid #3d2a8c;border-radius: 0.8rem;">
                        {{user.providerStatus==0?'未加盟':'已加盟'}}
                    </van-tag>
                </div>
                <div class="cell_left user_mobile"> {{user.mobile}}</div>
            </div>
        </div>
        <!-- 经营信息 -->
        <div class="management">
            <label class="title" >经营信息</label>
            <div class="content" >
                <div class="info">
                    <van-cell @click="scope_action_show = true;initTags();" style="padding:0px;">
                        <template #title>
                            <div class="photo"><img src="../../assets/image/business.png"/></div>
                            <span class="words" style="margin-left: 1rem;">经营范围</span>
                        </template>
                    </van-cell>
                </div>
                <div class="info">
                    <van-cell @click="img2_show = true" style="padding:0px;">
                        <template #title>
                            <div class="photo"><img src="../../assets/image/door.png"/></div>
                            <span class="words" style="margin-left: 1rem;">门头照片</span>
                        </template>
                    </van-cell>
                </div>
                <div class="info">
                    <van-cell @click="img1_show = true" style="padding:0px;">
                        <template #title>
                            <div class="photo"><img src="../../assets/image/licence.png"/></div>
                            <span class="words">经营许可证</span>
                        </template>
                    </van-cell>
                </div>
                <div class="info">
                    <van-cell @click="showLocation();" style="padding:0px;">
                        <template #title>
                            <div class="photo"><img src="../../assets/image/business_address.png"/></div>
                            <span class="words" style="margin-left: 1rem;">经营地址</span>
                    </template>
                    </van-cell>
                </div>
            </div>
        </div>
        <!-- 个人信息 -->
        <div class="management tops">
            <label class="title">个人信息</label>
            <div class="content">
                <div class="info">
                    <van-cell @click="img3_show=true" style="padding:0px;">
                        <template #title>
                            <div class="photo"><img src="../../assets/image/card.png"/></div>
                            <span class="words">身份证照片</span>
                        </template>
                    </van-cell>
                </div>
                <div class="info">
                    <van-cell @click="goWallet" style="padding:0px;">
                        <template #title>
                            <div class="photo"><img src="../../assets/image/money.png"/></div>
                            <span class="words" style="margin-left: 1rem;">我的钱包</span>
                        </template>
                    </van-cell>
                </div>
                <div class="info">
                    <van-cell @click="goQrcode" style="padding:0px;">
                        <template #title>
                            <div class="photo"><img src="../../assets/image/qrcode.png"/></div>
                            <span class="words">我的二维码</span>
                        </template>
                    </van-cell>
                </div>
                <!-- <div class="info">
                    <van-cell @click="goDiscount" style="padding:0px;">
                        <template #title>
                        <div class="photo"><img src="../../assets/image/coupon.png"/></div>
                        <span class="words">我的优惠券</span>
                        </template>
                    </van-cell>
                </div> -->
                <div class="info" v-if="providerUser&&(providerUser.departmentId!==0)">
                    <van-cell @click="goInvitePage" style="padding:0px;">
                        <template #title>
                        <div class="photo"><img src="../../assets/image/qrcode.png" /></div>
                        <span class="words">邀请二维码</span>
                        </template>
                    </van-cell>
                </div>
                <div class="info" v-if="providerUser&&(providerUser.departmentId!==0)">
                    <van-cell @click="inviteDialog=true" style="padding:0px;">
                        <template #title>
                        <div class="photo"><img src="../../assets/image/qrcode.png" /></div>
                        <span class="words">获取邀请码</span>
                        </template>
                    </van-cell>
                </div>
            </div>
        </div>
        <!-- 商户信息 -->
        <div  class="management tops">
            <van-cell-group style="padding:10px 0px;">
                <!-- <div class="cell_left" style="padding-left:1rem;">店铺Id</div><div class="cell_right" style="padding-right:1rem;"> {{providerUser.id}}</div> -->
                <van-cell title="店铺Id" :value="providerUser.id" />
                <van-cell title="商户名称" :value="merchantName" />
                <van-cell title="加盟时间" :value="user.joinedDate" />
                <van-cell title="身份证号码" :value="idCard" @click="idcardDialogShow=true;" />
            </van-cell-group>
        </div>
          
        <!-- 退出按钮                -->
        <div class="user_footer">
            <van-button type="default" @click="user_layout" plain   block style="border: 1px solid #4600af;border-radius: 5px;font-size: 16px;font-family: PingFang SC, PingFang SC-Regular;font-weight: 400;color: #4600af;">安全退出</van-button>
        </div>
        <!-- 弹出框 -->
        <van-action-sheet v-model="scope_action_show" @cancel="sheetOnCancel" title="选择您的经营范围">
            <div class="content">
                <van-checkbox-group v-model="result">
                    <van-cell-group>
                        <van-cell
                        v-for="(item, index) in businessScopeList"
                        clickable
                        :key="index"
                        :title="`${item.name}`"
                        @click="toggle(index)">
                        <template #right-icon>
                            <van-checkbox :name="item.id" ref="checkboxes" />
                        </template>
                        </van-cell>
                    </van-cell-group>
                </van-checkbox-group>
                <div style="margin-top:10px;">
                    <van-button @click="sheetOnSubmit" block type="primary">确定</van-button>
                    <van-button @click="sheetOnCancel" block >取消</van-button>
                </div>
            </div>
        </van-action-sheet>

        <van-dialog v-model="img1_show" style="text-align:center;" title="经营许可照" :closeOnClickOverlay='true' :showConfirmButton="false" >
            <van-empty image="error" v-if="!user.businessLicenceImg" description="未上传" />
            <img :src="$cosImg(user.businessLicenceImg)" v-else style="width:100%;height:auto;" />
            <van-uploader :after-read="afterRead_img2" >
                <van-button style="width:200px;margin:10px 0px;"  round block  type="primary">修改</van-button>
            </van-uploader> 
        </van-dialog>
        <van-dialog v-model="img2_show" style="text-align:center;" title="经营门头照片" :closeOnClickOverlay='true' :showConfirmButton="false">
            <van-empty image="error" v-if="!user.doorPhoto" description="未上传" />
            <img :src="$cosImg(user.doorPhoto)" v-else style="width:100%;height:auto;max-height:30vh;" />        
            <van-uploader :after-read="afterRead_img1" >
                <van-button style="width:200px;margin:10px 0px;"  round block  type="primary">修改</van-button>
            </van-uploader>    
        </van-dialog>
        <van-dialog v-model="img3_show" style="text-align:center;" title="身份证照片" :closeOnClickOverlay='true' :showConfirmButton="false" >
            <van-empty image="error" v-if="!user.idCardPhoto" description="未上传" />
            <img :src="$cosImg(user.idCardPhoto)" v-if="user.idCardPhoto" style="width:100%;height:auto;" />
            <van-uploader :after-read="afterRead_img3" >
                <van-button style="width:200px;margin:10px 0px;"  round block  type="primary">修改</van-button>
            </van-uploader> 
        </van-dialog>
        <van-dialog v-model="location_show" style="text-align:center;" title="详细地址" :closeOnClickOverlay='true' :showConfirmButton="false" >
            <div id="page" style="text-align:left;">
                <van-form @submit="locationSubmit">
                    <label style="margin-left:10px;">服务地区</label>
                    <van-field
                            style="border:1px solid #ced4da;"
                            v-model="fieldValue"
                            is-link
                            readonly
                            required
                            :rules="[{ required:true, message: '请选择您所在地区' }]"
                            placeholder="请选择您所在地区"
                            @click="city_show = true"/>
                    <label style="margin-left:10px;">详细地址</label>
                    <van-field
                            style="border:1px solid #ced4da;"
                            v-model="user.address"
                            required
                            maxlength="50"
                            :rules="[{ required: true, message: '请输入您的详细地址' }]"
                            placeholder="街道门牌、楼道房间号等信息"/>
                    <div style="margin: 16px;">
                        <van-button round block type="info" native-type="submit">提交</van-button>
                    </div>
                    <div style="margin: 16px;">
                        <van-button round block @click="locationCancel" native-type="button">取消</van-button>
                    </div>
                </van-form>                
            </div>
        </van-dialog>
        <van-popup v-model="city_show" round position="bottom">
            <van-cascader
                v-model="cascaderValue"
                title="请选择所在地区"
                :options="options"
                :field-names="{text: 'name',value: 'id',children: 'children',}"
                @close="city_show = false"
                @change="onCityChange"
                @finish="onFinish"/>
        </van-popup>
        <van-dialog v-model="cummission_show" title="分配比例" :closeOnClickOverlay='true' :showConfirmButton="false">
            <van-form ref="checkfrom" @submit='beforeClose' >
                <van-cell-group style="padding:10px">
                    <label>百分比抽成(%)</label>
                    <van-field v-model="formData.percentCommission" :rules="[{ required: true, message: '请输入百分比抽成' },{validator:percentVal,message:'百分比最大不能超过100'}]"  type="number" placeholder="请输入百分比抽成(%)" />
                    <label>最小金额(元)</label>
                    <van-field v-model="formData.minCommission" :rules="[{ required: true, message: '请输入最小金额' },{validator:minValidator,message:'最小金额不能小于0且不能大于99999'}]" type="number" placeholder="请输入最小金额(元)"  />
                    <label>最大金额(元)</label>
                    <van-field v-model="formData.maxCommission" :rules="[{ required: true, message: '请输入最大金额' },{validator:maxValidator,message:'最大金额不能小于最小金额且不能大于99999'},{validator:percentMaxValid,message:'请设置最大金额'},]" type="number" placeholder="请输入最大金额(元)" />
                </van-cell-group>
            </van-form>
            <div style="padding:10px;">
                <van-button style="width:48%;" @click="cummission_show=false" >取消</van-button>
                <van-button style="width:48%;" @click="$refs.checkfrom.submit()" type="primary">修改</van-button>
            </div>
        </van-dialog>
        <van-dialog v-model="inviteDialog" title="获取邀请码" show-cancel-button>
            <van-form ref="inviteFrom" @submit='getInviteCode' >
                <van-cell-group style="padding:10px" v-if="inviteCode" >
                    <label>手机号{{invitePhone}}的邀请码为：</label>
                    <span style="font-weight:bold;">{{inviteCode}}</span>
                </van-cell-group>
                <van-cell-group style="padding:10px" v-else>
                    <label>被邀请人手机号码</label>
                    <van-field v-model="invitePhone" type="phone" placeholder="请输入手机号码" />
                </van-cell-group>
            </van-form>
            <div style="padding:10px;">
                <van-button @click="$refs.inviteFrom.submit()" type="primary" block>获取</van-button>
            </div>
        </van-dialog>
        <van-dialog v-model="idcardDialogShow" title="完善信息" @confirm="updateIdCard" show-cancel-button>
            <van-form>
                <van-cell-group style="padding:10px">
                    <van-field v-model="idCard" type="phone" placeholder="请输入您的身份证号码" />
                </van-cell-group>
            </van-form>
        </van-dialog>
        <div class="btn_div">
        </div>
    </div>
</template>
<script>
import { Dialog,Uploader,Button,Cell,CellGroup,Field,Form,Tag,Popover,Icon,Empty,Popup,Cascader,ActionSheet,Checkbox,CheckboxGroup  } from 'vant';
import { ImagePreview } from 'vant';
import {providerInfo,userInfo} from '../../store/provider'
import {getTags,InviteCode,MPQRTicket,BusinessScope,providerUpdateIdCard,providerUpdateMerchantName,providerUpdateName,commission,saveDoorPhoto,saveLicencePhoto,saveIDCardPhoto,providerUpdate} from '../../api/provider'
import {userLogout} from '../../api/login'
import {getAddressList,GetAddressNameByIds} from '../../api/common'
import {logout} from '../../store/token'
import $util from '../../helper/util'
export default({
    name:'detail',
    components: {
        [Button.name]: Button,
        [Field.name]: Field,
        [Uploader.name]: Uploader,
        [Dialog.Component.name]: Dialog.Component,
        [CellGroup.name]: CellGroup,
        [Cell.name]: Cell,
        [Form.name]: Form,
        [Tag.name]: Tag,
        [Popover.name]: Popover,
        [Icon.name]: Icon,
        [Empty.name]: Empty,
        [Popup.name]: Popup,
        [Cascader.name]: Cascader,
        [ActionSheet.name]: ActionSheet,
        [Checkbox.name]: Checkbox,
        [CheckboxGroup.name]: CheckboxGroup,
    },
    data() {
        return {
            businessScopeList:[],
            result:[],
            invitePhone:'',
            inviteCode:'',
            inviteDialog:false,
            scope_action_show:false,
            idCard:'',
            idcard_show:false,
            idcardDialogShow:false,
            name_show:false,
            merchantName:'',
            merchant_name_show:false,
            merchant_name_msg:'',
            readonlyReadonly:true,
            cummission_show:false,
            providerId:'',
            showPopover:false,
            img1_show:false,
            img2_show:false,
            img3_show:false,
            location_show:false,
            id:this.$route.query.id,        
            user:'',  
            formData:{
                id: 0,
                maxCommission: '',
                minCommission: '',
                percentCommission: ''
            },
            cascaderValue:'',
            options:[],
            city_show:false,
            fieldValue:'',
            businessForm:{
                id:'',
                businessScope:''
            },
            // providerUser:''
        }
    },
    methods:{
        initTags(){
            getTags().then(res=>{
                this.businessScopeList = res.content.map(a=>{
                    return {id:a.id,name:a.tagName}
                })
            })
        },
        getInviteCode(){
            if(!this.invitePhone){
                this.$toast.fail("请输入邀请人手机号码")
                return
            }
            InviteCode({phone:this.invitePhone}).then(res=>{
                if(!res.status){
                    this.$toast.fail(res.message)
                }else{
                    this.inviteCode = res.data;
                }
            })
        },
        goInvitePage(){
            MPQRTicket().then(res=>{
                this.$toast({type:res.status?'success':'fail',message:res.message})
                if(res.data){
                    ImagePreview(['https://mp.weixin.qq.com/cgi-bin/showqrcode?ticket='+res.data]);
                }
            })
        },
        goQrcode(){
            var url = window.location.href;
            var type = '';
            if(url.indexOf('userPart')>-1){
                type = 1
            }
            this.$router.push({path:'/myQrcode',query:{name:this.user.name,providerId:this.providerId,mobile:this.user.mobile,type:type}})
        },
        goDiscount(){
            var user = userInfo.get();
            this.$router.push({path:'preferential',query:{wasteJoinStatus:user.wasteJoinStatus}});
        },
        goWallet(){
            if(!this.idCard){
             this.$toast.fail("请完善您的身份证号码！");
            }else{
                this.$router.push('/bank/wallet')
            }
        },
        sheetOnSubmit(){
            this.businessForm.id = this.user.id;
            this.businessForm.businessScope = this.result.join(',');
            this.$toast.loading({duration: 0,forbidClick: true,message: '提交中'});
            BusinessScope(this.businessForm).then(res=>{
                this.user.businessScope = this.businessForm.businessScope;
                this.$toast.clear();
                this.$toast({message:res.message,type:res.status?'success':'fail'});
                this.scope_action_show = false;
            })
        },
        sheetOnCancel(){            
            this.scope_action_show = false;
        },
        toggle(index) {
          this.$refs.checkboxes[index].toggle();
        },
        showLocation(){
            this.initCity();
            this.location_show=true;
            var ids = [this.user.province,this.user.city,this.user.country];
            GetAddressNameByIds(ids).then(res=>{
                this.fieldValue =res.map(a=>a.name).join('/');
            })
        },
        locationCancel(){
            this.location_show =false;
        },
        checkName(){
            if(!this.user||this.user.name.length==0||this.user.name.length>10){
                this.name_show = true;
            }else{
                this.name_show = false;
            }
        },
        checkIdCard(){
            var p = /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
            if(!p.test(this.idCard)){
                // this.idcard_show=true;
                return false
            }else{
                // this.idcard_show = false;
                return true
            }
        },
        checkMerchantName(){
            if(!this.merchantName){
                this.merchant_name_msg = '商户名称不能为空';
                this.merchant_name_show=true;
            }else{
                this.merchant_name_show = false;
            }
        },
         tagStyle(status){
            switch(status){
                case 1:
                    return '#3d2a8c';
                case 0:
                    return '#c1c1c1';
            }
        },
        updateIdCard(){
            var body = {
                id:this.user.id,
                idCard:this.idCard
            }
            if(!this.checkIdCard(this.idCard)){
                this.$toast.fail('身份证格式错误');
                return
            }
            providerUpdateIdCard(body).then(res=>{
                this.$toast({type:res.status?'success':'error',message:res.message})
                if(res.status){
                    this.providerUser.idCard = this.idCard;
                    providerInfo.set(this.providerUser);
                }
            }).catch(()=>{})
        },
        providerUpdateMerchantName(){
             if(this.merchantName){
                var body = {
                    id:this.user.id,
                    name:this.merchantName
                }
                providerUpdateMerchantName(body).then(res=>{
                    if(res.status){                        
                        this.providerUser.merchantName = this.merchantName;
                        providerInfo.set(this.providerUser);
                        this.merchant_name_show = false;
                    }else{
                        this.merchant_name_show = true;
                        this.merchant_name_msg = res.message;
                    }
                    this.merchant_name_msg = !res.status&&this.merchant_name_msg;
                });
            }
        },
        updateName(){
            if(this.user.name){
                var body = {
                    id:this.user.id,
                    name:this.user.name
                }
                providerUpdateName(body).then(res=>{
                    this.$toast({type:res.status?'success':'error',message:res.message})
                });
            }
        },
        percentVal(val){
            return val<=100&&val>=0;
        },
        minValidator(){
            var min = parseFloat(this.formData.minCommission);
            if(min>99999||min < 0){
                return false;
            }else{
                return true;
            }
        },
        maxValidator(){
            var max = parseFloat(this.formData.maxCommission);
            var min = parseFloat(this.formData.minCommission);
            if(max>99999||max < min){
                return false;
            }else{
                return true;
            }
        },
        percentMaxValid(){
            var max = parseFloat(this.formData.maxCommission);
            if(this.formData.percentCommission>0 && max<=0){
                return false;
            }else{
                return true;
            }
        },
        beforeClose(){
            this.formData.id = this.user.id;            
            commission(this.formData).then(res=>{
                this.$toast({
                    type:res.status?'success':'fail',
                    message:res.message
                })
                this.cummission_show=false;
                this.user.maxCommission = this.formData.maxCommission;
                this.user.minCommission = this.formData.minCommission;
                this.user.percentCommission = this.formData.percentCommission;
            });
        },
        user_layout(){
            userLogout().then(()=>{
                logout();
            }).catch(()=>{
                logout();
            });
        },
        afterRead_img1(file){
            this.uploadFile(file,0);
        },
        afterRead_img2(file){
            this.uploadFile(file,1);
        },
        afterRead_img3(file){
            this.uploadFile(file,2);
        },
        uploadFile(file,type){
            var filename = file.file.name;
            var ext = filename.substr(filename.lastIndexOf('.'));
            $util.uploadFile(ext,file,(err,data)=>{
                var filename = data.Location.substr(data.Location.lastIndexOf('/')+1);
                var fileurl = this.$cosImg(filename);
                if(type==0){
                    this.user.doorPhoto = fileurl;
                    saveDoorPhoto({doorPhoto:fileurl,id:this.user.id}).then(res=>{
                        this.$toast({type:res.status?'success':'error',message:res.message})
                    });
                }else if(type==1){
                    this.user.businessLicenceImg = fileurl;
                    saveLicencePhoto({photo:fileurl,id:this.user.id}).then(res=>{
                        this.$toast({type:res.status?'success':'error',message:res.message}) 
                    });
                }else if (type==2){
                    this.user.idCardPhoto = fileurl;
                    saveIDCardPhoto({photo:fileurl,id:this.user.id}).then(res=>{
                        this.$toast({type:res.status?'success':'error',message:res.message})
                    });
                }
            });
        },
        locationSubmit(){
            this.location_show =false;
            this.$toast.loading({duration: 0,forbidClick: true,message: '提交中'});
            providerUpdate(this.user).then(res=>{
                this.$toast.clear();
                this.$toast({
                    type:res.status?'success':'fail',
                    message:res.message
                })
            });
        },
        onCityChange({ value,selectedOptions}){
            this.initCity(value,selectedOptions);
        },
        onFinish({ selectedOptions }){
            this.city_show = false;
            this.options = [];
            this.initCity();
            this.fieldValue = selectedOptions.map((option) => option.name).join('/');
            var arr = selectedOptions.map((option) => option.id);
            this.user.province = arr&&arr[0];
            this.user.city = arr&&arr[1];
            this.user.country = arr&&arr[2];
        },
        initCity(id,selectedOptions){
            var body = {id:id};
            getAddressList(body).then((res)=>{    
                res.forEach(e=>{
                    if(e.isParent){e.children = [];}
                });
                if(this.options.length == 0||id == 0){
                    this.options =res;
                }else{
                    if(selectedOptions&&selectedOptions.length!=0){
                        selectedOptions[selectedOptions.length-1].children=res;
                    }
                }
            });
        },
        initProviderPage(){
            // var provider = providerInfo.get();
            // this.providerUser = provider;
            this.idCard = this.providerUser.idCard;
            this.merchantName = this.providerUser.merchantName;
            this.providerId = this.providerUser.providerId;            
            this.user = this.providerUser;
            this.checkName();
            this.formData.maxCommission= this.user.maxCommission||'0';
            this.formData.minCommission= this.user.minCommission||'0';
            this.formData.percentCommission= this.user.percentCommission||'';
            var arr = (this.user.businessScope && this.user.businessScope.split(','))||[];
            this.result = arr.map(a=>{
                return parseInt(a)
            })
        },
    },
    props:{
        providerUser:{
            type:Object,
            default:()=>{}
        }
    },
    watch:{
        providerUser(val){
            if(val){
                this.initProviderPage();
            }
        }
    },
    create(){
    }
})
</script>
<style scoped>
@import './mUserInfo.css';
</style>