<template>
    <div id="app_div">
        <div>
            <div v-show="active==0">
                <myorder :SubmitStatus="titleValue" ref="orderPage"></myorder>
            </div>
            <div v-show="active==1">
                <uploadorder :orderKey="orderkey" :showBatch='showBatch' :showStore='showStore'></uploadorder>
            </div>
            <div v-show="active==2">
                <mUserInfo  :providerUser='user'></mUserInfo>
            </div>
        </div>
        <div class="tabbar_bottom_div">
            <van-tabbar v-model="active"  @change="onChange">
                <van-tabbar-item v-for="(item,index) in titleArr"  :key="index" :icon="active==index?item.active_icon:item.icon">{{item.title}}</van-tabbar-item>
            </van-tabbar>
        </div>
    </div>
</template>
<script>
// import Cookies from "js-cookie"
import myorder from '../morder/myorder.vue'
import mUserInfo from '../mUserInfo/mUserInfo'
import uploadorder from '../upload/uploadOrder.vue'
import { Tabbar, Uploader,Field,Popup,Button,TabbarItem,CellGroup,Cell,DropdownMenu,DropdownItem  } from 'vant';
import {indexActive,providerInfo,userInfo} from '../../store/provider'
import {getUserInfo} from '../../api/provider'
import {allinpayUser} from '../../store/allinpay'
export default({
    name:'mIndex',
    components: {
        myorder,
        uploadorder,
        mUserInfo,
        [Button.name]: Button,
        [Popup.name]: Popup,
        [Field.name]: Field,
        [Uploader.name]: Uploader,
        [Tabbar.name]: Tabbar,
        [TabbarItem.name]: TabbarItem ,
        [CellGroup.name]: CellGroup ,
        [Cell.name]: Cell ,
        [DropdownMenu.name]: DropdownMenu,
        [DropdownItem.name]: DropdownItem,
    },
    data() {
        return {
            orderkey:0,
            payMoney:199,
            user:{},
            city_show:false,
            show:false,
            fileList:[],
            doorPhotoList:[],
            idCardlist:[],
            options:[],
            fieldValue:'',
            titleValue:1,
            showBatch:false,
            showStore:false,
            titleOption:[
                { text: '已提交报废车', value: 1 },
                { text: '客户查价报废车', value: 0 }],
            titleArr:[
                {title:'已提交报废车',active_icon:'wap-home',icon:'wap-home-o'},
                {title:'提交报废车',active_icon:'add',icon:'add-o'},
                {title:'我的信息',active_icon:'manager',icon:'manager-o'}],
            form:{
                province:0,
                city:0,
                country:0,
                address:'',
                merchantName:'',
                businessLicenceImg:'',
                name:'',
                idCard:'',
                name_err:'',
                id:'',
                doorPhoto:'',
                idCardImg:''
            },
            form_err:{
                name_err:'',
                identity_err:'',
            },
            active: 1,
        }
    },
    methods:{        
        dropDownChange(){
            this.$refs.orderPage.SubmitStatus = this.titleValue;
            this.$refs.orderPage.onRefresh();
        },
        onChange(index) {
            this.title =  this.titleArr[index].title;
            indexActive.set(index);
        },
        getPorviderInfo(){
            this.$toast.loading({duration: 0,forbidClick: true,message: '加载中'});
            getUserInfo().then(res=>{
                this.$toast.clear();
                this.user = res.data;
                if(this.user){
                    this.form.name = this.user.name;
                    this.form.id = this.user.providerId;
                }
                providerInfo.set(res.data);
                this.showBatch =  res.data&&res.data.departmentId==8;
                var ignoreDeptId = [0,1]
                this.showStore = res.data&&(ignoreDeptId.indexOf(res.data.departmentId)==-1)
                this.result =(this.user.businessScope && this.user.businessScope.split(','))||[];
                console.log('user',this.user)
                //如果信息不完善，则跳转到注册页面
                //TODO 这里需要返回特定状态，用于盘点用户注册到哪一步了
                var loginUser= userInfo.get();
                if(!loginUser.isCompleted){
                    allinpayUser.update({userId:loginUser.id,userSource:1,newUser:true})
                    this.$router.push({path:'/bank/auth',query:{isFirst:true}});
                }else if(res.data.providerStatus==0){
                    this.$router.replace('/userPay')
                } 
            }).catch(error=>{
                // eslint-disable-next-line no-debugger
                debugger
                console.log(error);
                this.$toast.clear();
                // window.location.reload();
            });
        }
    },
    mounted(){
        document.getElementsByTagName('body')[0].style.backgroundColor='#fff';
        this.active = parseInt(indexActive.get());
    },
    created(){
        // var payUser = allinpayUser.get();
        // else if(!payUser.mobile){
        //     this.$router.push({path:'/bank/auth',query:{isFirst:true}});
        // }
        this.getPorviderInfo();        
    }
})
</script>
<style scoped>
@import './mindex.css';
</style>